<template>
  <table
    cellpadding="0"
    cellspacing="0"
    style="
      vertical-align: -webkit-baseline-middle;
      font-size: large;
      font-family: Arial;
    "
  >
    <tbody>
      <tr>
        <td>
          <table>
            <tbody>
              <tr>
                <td style="vertical-align: top">
                  <table>
                    <tbody>
                      <tr>
                        <img
                          :src="picture"
                          role="presentation"
                          width="130"
                          style="
                            height: 100%;
                            display: block;
                            border-radius: 50%;
                          "
                        />
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td width="46"><div></div></td>
                <td style="padding: 0px; vertical-align: middle">
                  <h3
                    color="#000000"
                    style="margin: 0px; font-size: 20px; color: #0d48cc"
                  >
                    <span>{{firstname}}</span><span>&nbsp;</span
                    ><span>{{ lastname }}</span>
                  </h3>
                  <p
                    color="#000000"
                    font-size="large"
                    style="
                      margin: 0px;
                      color: rgb(0, 0, 0);
                      font-size: 16px;
                      line-height: 24px;
                    "
                  >
                    <span>{{work}}</span>
                  </p>
                  <p
                    color="#000000"
                    font-size="large"
                    style="
                      margin: 0px;
                      font-weight: 500;
                      color: rgb(0, 0, 0);
                      font-size: 16px;
                      line-height: 24px;
                    "
                  ></p>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    style="
                      vertical-align: -webkit-baseline-middle;
                      font-size: large;
                      font-family: Arial;
                      width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td height="10"></td>
                      </tr>
                      <tr>
                        <td
                          color="#F2547D"
                          direction="horizontal"
                          height="1"
                          style="
                            width: 100%;
                            border-bottom: 1px solid #0d48cc;
                            border-left: none;
                            display: block;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td height="10"></td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    style="
                      vertical-align: -webkit-baseline-middle;
                      font-size: large;
                      font-family: Arial;
                    "
                  >
                    <tbody>
                      <tr height="25" style="vertical-align: middle">
                        <td width="30" style="vertical-align: middle">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              vertical-align: -webkit-baseline-middle;
                              font-size: large;
                              font-family: Arial;
                            "
                          >
                            <tbody>
                              <tr>
                                <td style="vertical-align: bottom">
                                  <span
                                    color="#F2547D"
                                    width="11"
                                    style="
                                      display: block;
                                      background-color: #0d48cc;
                                    "
                                    ><img
                                      src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/phone-icon-2x.png"
                                      width="13"
                                      style="
                                        display: block;
                                        background-color: #0d48cc;
                                      "
                                  /></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="padding: 0px; color: rgb(0, 0, 0)">
                          <a
                            href="tel:0428293592"
                            color="#000000"
                            style="
                              text-decoration: none;
                              color: rgb(0, 0, 0);
                              font-size: 12px;
                            "
                            ><span>0428293592</span></a
                          >
                        </td>
                      </tr>
                      <tr height="25" style="vertical-align: middle">
                        <td width="30" style="vertical-align: middle">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              vertical-align: -webkit-baseline-middle;
                              font-size: large;
                              font-family: Arial;
                            "
                          >
                            <tbody>
                              <tr>
                                <td style="vertical-align: bottom">
                                  <span
                                    color="#F2547D"
                                    width="11"
                                    style="
                                      display: block;
                                      background-color: #0d48cc;
                                    "
                                    ><img
                                      src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/email-icon-2x.png"
                                      color="#F2547D"
                                      width="13"
                                      style="
                                        display: block;
                                        background-color: #0d48cc;
                                      "
                                  /></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="padding: 0px">
                          <a
                            :href="'mailto:' + email"
                            color="#000000"
                            style="
                              text-decoration: none;
                              color: rgb(0, 0, 0);
                              font-size: 12px;
                            "
                            ><span>{{email}}</span></a
                          >
                        </td>
                      </tr>
                      <tr height="25" style="vertical-align: middle">
                        <td width="30" style="vertical-align: middle">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              vertical-align: -webkit-baseline-middle;
                              font-size: large;
                              font-family: Arial;
                            "
                          >
                            <tbody>
                              <tr>
                                <td style="vertical-align: bottom">
                                  <span
                                    color="#F2547D"
                                    width="11"
                                    style="
                                      display: block;
                                      background-color: #0d48cc;
                                    "
                                    ><img
                                      src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/link-icon-2x.png"
                                      color="#F2547D"
                                      width="13"
                                      style="
                                        display: block;
                                        background-color: #0d48cc;
                                      "
                                  /></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="padding: 0px">
                          <a
                            href="https://walkingdog.fr"
                            color="#000000"
                            style="
                              text-decoration: none;
                              color: rgb(0, 0, 0);
                              font-size: 12px;
                            "
                            ><span>walkingdog.fr</span></a
                          >
                        </td>
                      </tr>
                      <tr height="25" style="vertical-align: middle">
                        <td width="30" style="vertical-align: middle">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              vertical-align: -webkit-baseline-middle;
                              font-size: large;
                              font-family: Arial;
                            "
                          >
                            <tbody>
                              <tr>
                                <td style="vertical-align: bottom">
                                  <span
                                    color="#F2547D"
                                    width="11"
                                    style="
                                      display: block;
                                      background-color: #0d48cc;
                                    "
                                    ><img
                                      src="https://cdn2.hubspot.net/hubfs/53/tools/email-signature-generator/icons/address-icon-2x.png"
                                      color="#F2547D"
                                      width="13"
                                      style="
                                        display: block;
                                        background-color: #0d48cc;
                                      "
                                  /></span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td style="padding: 0px">
                          <span
                            color="#000000"
                            style="font-size: 12px; color: rgb(0, 0, 0)"
                            ><span
                              >350 Rue Barthélemy Thimonnier, 69530 Brignais,
                              France</span
                            ></span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    style="
                      vertical-align: -webkit-baseline-middle;
                      font-size: large;
                      font-family: Arial;
                      width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td height="10"></td>
                      </tr>
                      <tr>
                        <td
                          color="#F2547D"
                          direction="horizontal"
                          height="1"
                          style="
                            width: 100%;
                            border-bottom: 1px solid #0d48cc;
                            border-left: none;
                            display: block;
                          "
                        ></td>
                      </tr>
                      <tr>
                        <td height="10"></td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    cellpadding="0"
                    cellspacing="0"
                    style="
                      vertical-align: -webkit-baseline-middle;
                      font-family: Arial;
                      width: 100%;
                    "
                  >
                    <tbody>
                      <tr>
                        <td style="vertical-align: top">
                          <img
                            src="https://pbs.twimg.com/profile_images/1497200238565670927/Or2Zlcqp_400x400.jpg"
                            width="160"
                            style="max-width: 80px; display: inline-block; border-radius: 50px; height: 100;"
                          />
                        </td>
                        <td style="text-align: right; vertical-align: top">
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            style="
                              vertical-align: -webkit-baseline-middle;
                              font-family: Arial;
                              display: inline-block;
                            "
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <a
                                    href="https://fr-fr.facebook.com/walkingdogfr"
                                    color="#1a90e1"
                                    style="
                                      display: inline-block;
                                      padding: 0px;
                                    "
                                    target="_blank"
                                    ><img
                                      src="https://cdn-icons-png.flaticon.com/512/145/145802.png"
                                      alt="facebook"
                                      color="#1a90e1"
                                      height="24"
                                      style="max-width: 135px; display: block"
                                  /></a>
                                </td>
                                <td width="5"><div></div></td>
                                <td>
                                  <a
                                    href="https://twitter.com/WalkingDogfr"
                                    color="#1a90e1"
                                    style="
                                      display: inline-block;
                                      padding: 0px;
                                    "
                                    target="_blank"
                                    ><img
                                      src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                                      alt="linkedin"
                                      color="#1a90e1"
                                      height="24"
                                      style="max-width: 135px; display: block"
                                  /></a>
                                </td>
                                <td width="5"><div></div></td>
                                 <td>
                                  <a
                                    href="https://www.instagram.com/walkingdogfr/"
                                    color="#1a90e1"
                                    style="
                                      display: inline-block;
                                      padding: 0px;
                                    "
                                    target="_blank"
                                    ><img
                                      src="https://cdn-icons-png.flaticon.com/512/174/174855.png"
                                      alt="linkedin"
                                      color="#1a90e1"
                                      height="24"
                                      style="max-width: 135px; display: block"
                                  /></a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Signature-wg",
  props: {
    firstname: String,
    lastname: String,
    work: String,
    email: String,
    picture: String,
  },
};
</script>
