<template>
    <h2 v-if="user">{{ user }}</h2>
    <button @click="handleClickSignIn">Upload Signature</button>
    <div style="{align-items: center; display: flex;flex-wrap: wrap;}">
        <div style="{width:500px; background-color: red}">
            <input type="radio" id="sign1" name="age" value="getinov" @click="this.selected = 'getinov'" />
            <Signature :firstname="this.firstname" :lastname="this.lastname" :work="this.work" :email="this.email" :picture="this.picture" />
        </div>
        <!-- <div>
            <input type="radio" id="sign2" name="age" value="inealab" />
            <Inea :firstname="this.firstname" :lastname="this.lastname" :work="this.work" :email="this.email" :picture="this.picture" />
        </div> -->
        <!-- <div>
            <input type="radio" id="sign3" name="age" value="arkinea" />
            <Arkinea :firstname="this.firstname" :lastname="this.lastname" :work="this.work" :email="this.email" :picture="this.picture" />
        </div> -->
        <div>
            <input type="radio" id="sign4" name="age" value="walking-dog" @click="this.selected = 'walking-dog'" />
            <Wg :firstname="this.firstname" :lastname="this.lastname" :work="this.work" :email="this.email" :picture="this.picture" />
        </div>
    </div>
</template>
<script>
import Signature from './Signature.vue';
// import Inea from './Signature-inealab.vue';
// import Arkinea from './Signature-arkinea.vue';
import Wg from './Signature-wg.vue';
import axios from 'axios';
import { inject, toRefs } from 'vue';

export default {
    components: {
        Signature,
        // Inea,
        // Arkinea,
        Wg,
    },
    data() {
        return {
            firstname: null,
            lastname: null,
            picture: null,
            email: null,
            work: null,
            google: false,
            user: '',
            selected: null,
        };
    },
    setup(props) {
        const { isSignIn } = toRefs(props);
        const Vue3GoogleOauth = inject('Vue3GoogleOauth');

        const handleClickLogin = () => {};
        return {
            Vue3GoogleOauth,
            handleClickLogin,
            isSignIn,
        };
    },
    methods: {
        async handleClickSignIn() {
            if (this.selected != null) {
                try {
                    const googleUser = await this.$gAuth.signIn();
                    if (!googleUser) return null;
                    axios
                        .get(process.env.VUE_APP_USER_API_URL + '/auth/google/provided?code=' + googleUser.getAuthResponse().access_token + '&signature=' + this.selected, { headers: { Authorization: 'Bearer ' + localStorage.getItem('user-token') } })
                        .then(res => {
                            console.log(res);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                } catch (error) {
                    console.error(error);
                    return null;
                }
            }
        },
    },
    mounted() {
        axios
            .get(process.env.VUE_APP_USER_API_URL + '/user/me', {
                headers: { authorization: 'Bearer ' + localStorage.getItem('user-token') },
            })
            .then(res => {
                console.log(res.data);
                this.firstname = res.data.firstname;
                this.lastname = res.data.lastname;
                this.picture = res.data.picture;
                this.email = res.data.email;
                this.work = res.data.work;
                this.google = res.data.google ? true : false;
            })
            .catch(err => {
                throw err;
            });
    },
};
</script>

<style lang="scss"></style>
